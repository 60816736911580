import React from 'react';

/* Default Navigation */

export const navbarLinks = [{
  key: 'ieeeorg',
  href: 'https://www.ieee.org/',
  label: 'IEEE.org'
},{
  key: 'ieeexplore',
  href: 'https://ieeexplore.ieee.org/Xplore/home.jsp',
  label: 'IEEE Xplore Digital Library'
},{
  key: 'ieeestandards',
  href: 'https://standards.ieee.org/',
  label: 'IEEE Standards'
},{
  key: 'ieeespectrum',
  href: 'https://spectrum.ieee.org/',
  label: 'IEEE Spectrum'
},{
  key: 'authorcenter',
  href: 'https://ieeeauthorcenter.ieee.org/',
  label: 'IEEE Author Center'
}];
/*
},{
  key: 'moresites',
  href: 'https://www.ieee.org/sitemap.html',
  label: 'More Sites',
},{
  key: 'joinieee',
  href: 'https://ieeeauthorcenter.ieee.org/ieee-membership-advantages/',
  label: 'Join IEEE',
  className: 'join'
}];
*/

export const footerLinks = [{
  key: 'home',
  href: 'https://www.ieee.org',
  label: 'Home',
  bar: '\u00A0|\u00A0'
  /*
  FPT: 2/28/2025, this used to be a link to the main page of RefPrep. New version of footer
  wants to point to IEEE home.
  target: '_self'
  */
},{
  key: 'sitemap',
  href: 'https://www.ieee.org/sitemap.html',
  label: 'Sitemap/More Sites',
  bar: '\u00A0|\u00A0'
},{
  key: 'contact',
  href: 'https://www.ieee.org/about/contact_center/index.html',
  label: 'Contact',
  bar: '\u00A0|\u00A0'
},{
  key: 'accessibility',
  href: 'https://www.ieee.org/accessibility_statement.html',
  label: 'Accessibility',
  bar: '\u00A0|\u00A0'
},{
  key: 'nondiscrimination',
  href: 'https://www.ieee.org/about/corporate/governance/p9-26.html',
  label: 'Nondiscrimination Policy',
  bar: '\u00A0|\u00A0'
},{
  key: 'ethics',
  href: 'http://ieee-ethics-reporting.org/',
  label: 'IEEE Ethics Reporting',
  bar: '\u00A0|\u00A0'
},{
  key: 'privacy',
  href: 'https://www.ieee.org/security-privacy.html',
  label: 'Privacy Policy',
  bar: '\u00A0|\u00A0'
},{
  key: 'terms',
  href: 'https://www.ieee.org/about/help/site-terms-conditions.html',
  label: 'Terms & Disclosures',
  bar: ''
}];

const year = new Date().getFullYear();

export const copyright = <span>© Copyright {year} IEEE – All rights reserved.</span>
export const tagline = <span>A public charity, IEEE is the world's largest technical professional organization dedicated to advancing technology for the benefit of humanity.</span>
